import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, createSlots as _createSlots, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "pt-6 pb-4" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_CheckboxV2 = _resolveComponent("CheckboxV2")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_EditAddress = _resolveComponent("EditAddress")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 0,
          title: _ctx.confirmValue.title,
          message: _ctx.confirmValue.message,
          image: "warning",
          isDisabled: _ctx.isDisabledButtonBatalkan,
          textSuccess: _ctx.confirmValue.textSuccess,
          textCancel: _ctx.confirmValue.textCancel,
          class: "px-8",
          onClose: _ctx.confirmationCancel,
          onSubmit: _ctx.confirmationSubmit
        }, {
          custom: _withCtx(() => [
            _createVNode("div", _hoisted_1, [
              _createVNode(_component_Select, {
                class: "w-full text-left",
                isDefaultBlack: "",
                isShowDefault: false,
                dataSelect: _ctx.rejectedReasons,
                value: _ctx.selectedReasonValue,
                isOpenMenu: _ctx.openSelect,
                onOpenSelect: () => (_ctx.openSelect = true),
                onCloseSelect: () => (_ctx.openSelect = false),
                onChooseSelect: 
            (name, val) => {
              _ctx.selectedReasonName = name;
              _ctx.selectedReasonValue = val;
              _ctx.openSelect = false;
            }
          ,
                isDisabled: _ctx.isDisableSelectReason
              }, null, 8, ["dataSelect", "value", "isOpenMenu", "onOpenSelect", "onCloseSelect", "onChooseSelect", "isDisabled"]),
              (_ctx.isReasonAbleToGenerateNewStt)
                ? (_openBlock(), _createBlock(_component_CheckboxV2, {
                    key: 0,
                    class: "pt-3 text-14px",
                    label: "Buat STT baru untuk dikembalikan",
                    labelWeight: "regular",
                    value: _ctx.isGenerateNewStt,
                    "onUpdate:value": _cache[1] || (_cache[1] = $event => (_ctx.isGenerateNewStt = $event))
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["title", "message", "isDisabled", "textSuccess", "textCancel", "onClose", "onSubmit"]))
      : _createCommentVNode("", true),
    _withDirectives(_createVNode(_component_Modal, {
      onSubmit: !_ctx.isDetail ? _ctx.directFinish : _ctx.closeModalSuccess,
      title: _ctx.titleFinish,
      message: _ctx.messageFinish,
      image: "image-modal-success",
      textSuccess: _ctx.successFinish,
      textCancel: _ctx.isGenerateNewStt ? 'OK' : '',
      onClose: !_ctx.isDetail ? _ctx.directFinish : _ctx.closeModalSuccess,
      class: "px-8"
    }, _createSlots({ _: 2 }, [
      (_ctx.isGenerateNewStt)
        ? {
            name: "custom-button",
            fn: _withCtx(() => [
              _createVNode(_component_lp_button, _mergeProps(_ctx.customStyleButtonNewStt, {
                icon: 
          _ctx.$refs['op-success']?.visible
            ? `chevron-up-white`
            : `chevron-down-white`
        ,
                onClick: _ctx.togglePanelPrintSuccess
              }), null, 16, ["icon", "onClick"]),
              _createVNode(_component_OverlayPanel, {
                ref: "op-success",
                appendTo: "body",
                style: {"width":"auto"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.customStyleButtonNewStt.groupOptions, (option, groupIndex) => {
                    return (_openBlock(), _createBlock(_Fragment, {
                      key: option.label
                    }, [
                      _createVNode(_component_LpButton, {
                        onClick: 
              () => {
                option.clickFunction();
                _ctx.$refs['op-success'].hide();
              }
            ,
                        class: "w-auto",
                        flat: "",
                        dense: "",
                        iconLeft: option.icon,
                        title: option.label
                      }, null, 8, ["onClick", "iconLeft", "title"]),
                      (groupIndex < _ctx.customStyleButtonNewStt.groupOptions.length - 1)
                        ? (_openBlock(), _createBlock("hr", _hoisted_2))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]),
                _: 1
              }, 512)
            ])
          }
        : undefined
    ]), 1032, ["onSubmit", "title", "message", "textSuccess", "textCancel", "onClose"]), [
      [_vShow, _ctx.confirmationFinish]
    ]),
    (_ctx.editAddress)
      ? (_openBlock(), _createBlock(_component_EditAddress, {
          key: 1,
          defaultStt: _ctx.formEditAddress,
          posField: true,
          onClose: _ctx.closeEditAddress,
          onOnSubmit: _ctx.cancelBookingWithGenerateStt
        }, null, 8, ["defaultStt", "onClose", "onOnSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}